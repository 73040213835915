import React, { useEffect, useState } from 'react';

const SampleSongs = ({ onSelect }) => {
  const [sampleSongs, setSampleSongs] = useState([]);

  useEffect(() => {
    const fetchSampleSongs = async () => {
      console.log("Fetching sample songs")
      try {
        //const response = await fetch('http://localhost:5000/api/sample-songs');
        const response = await fetch('/api/sample-songs');
        const data = await response.json();
        setSampleSongs(data);
      } catch (error) {
        console.error("Error fetching sample songs:", error);
      }
    };
    fetchSampleSongs();
  }, []);

  return (
    <div className="mt-4">
      <h2 className="text-xl font-spotifyreg text-center">Here are some popular tracks to try out!</h2>
      <p className="text-center">Select a track to see interesting details about that song, it's even better in visualizer mode!</p>
      <div className="mt-4 flex flex-wrap justify-center gap-4 md:flex-row md:gap-4 sm:flex-col">
        {sampleSongs.length > 0 ? (
          sampleSongs.map((track) => (
            <div
              key={track.id}
              className="p-4 border rounded-lg shadow-lg cursor-pointer w-full md:w-1/6" // Adjust width for responsiveness
              onClick={() => onSelect(track)}
            >
              <img
                src={track.album.images[0]?.url}
                alt={`${track.name} album cover`}
                className="mt-2 w-full h-auto rounded-lg"
              />
              <h2 className="text-xl font-semibold">{track.name}</h2>
              <p className="text-gray-400">
                {track.artists.map((artist) => artist.name).join(', ')}
              </p>
            </div>
          ))
        ) : (
          <p>No sample songs available.</p>
        )}
      </div>
    </div>
  );
};

export default SampleSongs;