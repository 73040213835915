import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { TooltipTag } from './TooltipDetail';

const LoudnessGauge = ({ loudness, tooltipId, tooltipContent }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const myChart = echarts.init(chartRef.current);

    const option = {
      series: [
        {
          type: 'gauge',
          min: -60,
          max: 0,
          radius: '50%',
          axisLine: {
            lineStyle: {
              color: [[1, '#ffffff']],
              width: 3,
            },
          },
          splitLine: {
            distance: -18,
            length: 18,
            lineStyle: {
              color: '#ffffff',
            },
          },
          axisTick: {
            distance: -12,
            length: 10,
            lineStyle: {
              color: '#ffffff',
            },
          },
          axisLabel: {
            distance: -40,
            color: '#ffffff',
            fontSize: 15,
          },
          pointer: {
            show: true,
            length: '115%',
            itemStyle: {
              color: '#1ED760',
            },
          },
          detail: {
            formatter: `{value} dB`,
            valueAnimation: false,
            fontSize: 15,
            color: '#ffffff',
            offsetCenter: {
              0: '0%',
              1: '100%',
            },
          },
          title: {
            formatter: `Loudness (dB)`,
            fontSize: 15,
            color: '#ffffff',
            offsetCenter: {
              0: '0%',
              1: '130%',
            },
          },
          data: [
            {
              value: loudness,
              name: 'Loudness (dB)',
            },
          ],
        },
      ],
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose(); // Clean up the chart on unmount
    };
  }, [loudness]);

  return (
    <TooltipTag tooltipId={tooltipId} tooltipContent={tooltipContent}>
      <div ref={chartRef} style={{ width: '300px', height: '300px' }} />
    </TooltipTag>
  );
};

export default LoudnessGauge;