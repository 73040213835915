import React from 'react';
import styled from 'styled-components';
import ReactSlider from 'react-slider';

const StyledSlider = styled(ReactSlider)`
  width: 100%; /* Full width for horizontal slider */
  height: 25px;
`;

const StyledThumb = styled.div`
  height: 20px;
  width: 60px;
  background-image: url('/img/level.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: -25px;
  margin-top: 3px;
  transform: rotate(90deg);
`;

const Thumb = ({ key: keyProp, ...props }, state) => <StyledThumb key={keyProp} {...props} />;

const StyledTrack = styled.div`
  background: ${props => (props.index === 2 ? '#f00' : props.index === 1 ? '#a3a3a3' : '#1ED760')};
  border-radius: 999px;
  height: 100%;
`;

const Track = ({ key: keyProp, ...props }, state) => <StyledTrack key={keyProp} {...props} index={state.index} />;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: full;
  margin: 20px;
`;

const HorizontalSlider = ({ value, label }) => (
  <StyledContainer>
    <div className='flex gap-x-5 items-center min-w-full'>
        <div className="lcd-box p-2 rounded-lg bg-green-300 text-black text-center w-[80px]">
          <p>{value.toFixed(2)}</p>
        </div>
    <StyledSlider
      value={value}  // Changed from defaultValue to value for controlled component behavior
      min={0}
      max={100}
      orientation="horizontal"
      renderTrack={Track}
      renderThumb={Thumb}

      disabled={true}
    />
    </div>
    <br></br>
    <p className="text-white font-bold mb-2">{label}</p>
  </StyledContainer>
);

export default HorizontalSlider;