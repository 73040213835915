import TooltipDetail, { generateTooltipDetails } from "./TooltipDetail";

const TooltipList = ({ track, features }) => {
    const details = generateTooltipDetails(track, features);

    return (
      <div>
        {details.map(({ id, content, label, value }) => (
          <TooltipDetail
            key={id}
            tooltipId={id}
            tooltipContent={content}
            label={label}
            value={value}
          />
        ))}
      </div>
    );
  };

export default TooltipList;