import React from 'react';
import { Tooltip } from 'react-tooltip';

// Function to map the key to standard pitch class notation
export const mapKeyToNotation = (key) => {
  const keyMap = [
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#",
    "A",
    "A#",
    "B",
  ];
  return key === -1 ? "No key detected" : keyMap[key];
};

// Function to display time signature as a fraction
export const formatTimeSignature = (timeSignature) => {
  return `${timeSignature}/4`;
};

// Function to convert mode
export const mapMode = (mode) => {
  return mode === 0 ? "Minor" : mode === 1 ? "Major" : "Unknown";
};

// Function to convert duration from milliseconds to minutes and seconds
export const formatDuration = (durationMs) => {
  const minutes = Math.floor(durationMs / 60000);
  const seconds = Math.floor((durationMs % 60000) / 1000);
  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};

export const AlbumCover = ({ src, alt, size }) => (
  <div
    data-tooltip-id="album-art"
    data-tooltip-content="The cover art for the album."
  >
    <img
      src={src}
      alt={alt}
      className="mt-2 rounded-lg"
      style={{ height: `${size}px`, width: `${size}px` }}
    />
    <Tooltip id="album-art" className="max-w-[400px] break-words" />
  </div>
);


