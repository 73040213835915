import React from 'react';
import styled from 'styled-components';
import ReactSlider from 'react-slider';

const StyledSlider = styled(ReactSlider)`
  height: 100%; /* Full height for vertical slider */
  width: 25px;
`;

const StyledThumb = styled.div`
  height: 20px;
  width: 60px;
  background-image: url('/img/level.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: -17px;
`;

const Thumb = ({ key: keyProp, ...props }, state) => <StyledThumb key={keyProp} {...props} />;

const StyledTrack = styled.div`
  background: ${props => (props.index === 2 ? '#f00' : props.index === 1 ? '#a3a3a3' : '#1ED760')};
  border-radius: 999px;
  width: 100%;
`;

const Track = ({ key: keyProp, ...props }, state) => <StyledTrack key={keyProp} {...props} index={state.index} />;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
  margin: 0 10px;
`;

const VerticalSlider = ({ value, label }) => (
  <StyledContainer>
        <div className="lcd-box p-2 rounded-lg bg-green-300 text-black text-center w-[80px]">
          <p>{value.toFixed(2)}</p>
        </div>
        <br></br>
    <StyledSlider
      value={value}  // Changed from defaultValue to value for controlled component behavior
      min={0}
      max={100}
      orientation="vertical"
      renderTrack={Track}
      renderThumb={Thumb}
      invert={true}
      disabled={true}
    />
    <p className="text-white font-bold mb-2">{label}</p>
  </StyledContainer>
);

export default VerticalSlider;