import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from './components/Navbar';
import SampleSongs from './components/SampleSongs';
import TrackDetails from './components/TrackDetails';
import Footer from './components/Footer';
import './fonts.css';

function App() {
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [audioFeatures, setAudioFeatures] = useState(null);
  const [tracks, setTracks] = useState([]);
  const [searchAttempted, setSearchAttempted] = useState(false);

  const handleSearch = async (searchQuery) => {
    // Clear selected track details on new search
    setSelectedTrack(null);
    setAudioFeatures(null);
    setSearchAttempted(true);

    try {
      //const response = await fetch(`http://localhost:5000/api/search?query=${searchQuery}`);
      const response = await fetch(`/api/search?query=${searchQuery}`);
      const data = await response.json();

      if (data.tracks.items.length > 0) {
        setTracks(data.tracks.items);
      } else {
        setTracks([]);
        console.log("No results found.");
      }
    } catch (error) {
      console.error("Error fetching tracks:", error);
      setTracks([]);
    }
  };

  const handleTrackSelect = async (track) => {
    setSelectedTrack(track);

    try {
      //const featuresResponse = await fetch(`http://localhost:5000/api/features?trackId=${track.id}`);
      const featuresResponse = await fetch(`/api/features?trackId=${track.id}`);
      const featuresData = await featuresResponse.json();
      setAudioFeatures(featuresData);

      const artistId = track.artists[0].id;
      //const artistResponse = await fetch(`http://localhost:5000/api/artist?artistId=${artistId}`);
      const artistResponse = await fetch(`/api/artist?artistId=${artistId}`);
      const artistData = await artistResponse.json();

      setSelectedTrack({ ...track, genre: artistData.genres.join(', ') });
      setTracks([]); // Clear search results after selection
    } catch (error) {
      console.error("Error fetching track details or artist genre:", error);
    }
  };

  const handleBack = () => {
    setSelectedTrack(null);
    setAudioFeatures(null);
    setSearchAttempted(false);
  };

  return (
    <Router>
      <div className="App">
        <Navbar onSearch={handleSearch} />

        <div className="main-content p-4">
          <h1 className="font-spotify text-2xl text-center">Search for a Beat's Deetz!</h1>

{/* Only show SampleSongs if no track is selected */}
{!selectedTrack ? (
  <>
    {/* Display SampleSongs if no search has been attempted or if no results are found */}
    {!searchAttempted || (searchAttempted && tracks.length === 0) ? (
      <div className="mt-4 flex justify-center">
        <SampleSongs onSelect={handleTrackSelect} />
      </div>
    ) : null}

    {/* Display the message only if there are search results */}
    {tracks.length > 0 && (
      <p className="text-center">
        Select a track from your search results to see interesting details about that song, it's even better in visualizer mode!
      </p>
    )}

    {/* Display search results in a row */}
    <div className="mt-4 flex flex-wrap justify-center gap-4 md:flex-row md:gap-4 sm:flex-col">
      {tracks.length > 0 ? (
        tracks.map((track) => (
          <div
            key={track.id}
            className="p-4 border rounded-lg shadow-lg cursor-pointer w-full md:w-1/6"
            onClick={() => handleTrackSelect(track)} // Select track when clicked
          >
            <img
              src={track.album.images[0]?.url}
              alt={`${track.name} album cover`}
              className="mt-2 w-full h-auto rounded-lg"
            />
            <h2 className="text-xl font-semibold">{track.name}</h2>
            <p className="text-gray-400">
              {track.artists.map((artist) => artist.name).join(', ')}
            </p>
          </div>
        ))
      ) : (
        searchAttempted && (
          <p className="text-red-500 mt-4 text-center">No results found. Please try a different song name.</p>
        )
      )}
    </div>
  </>
) : (
  // Display TrackDetails with audio features when a track is selected
  <TrackDetails track={selectedTrack} features={audioFeatures} onBack={handleBack} />
)}
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
