import React from "react";
import { formatDuration } from "./utils";
import { Tooltip } from "react-tooltip";
import { generateTooltipDetails, TooltipTag } from "./TooltipDetail";

const TrackPanel = ({ track, features }) => {
  const tooltipDetails = generateTooltipDetails(track, features);

  const artistTooltip = tooltipDetails.find((detail) => detail.id === "artist");
  const albumTooltip = tooltipDetails.find((detail) => detail.id === "album");
  const durationTooltip = tooltipDetails.find(
    (detail) => detail.id === "duration"
  );

  return (
    <div className="lcd-box p-4 m-4 rounded-lg bg-green-300 text-black">
      <h2
        className="text-xl"
        data-tooltip-id="track"
        data-tooltip-content="The name of the track."
      >
        {track.name}
      </h2>
      <Tooltip id="track" className="max-w-[400px] break-words" />

      <TooltipTag
        tooltipId={artistTooltip?.id}
        tooltipContent={
          artistTooltip?.content || "Artist information not available"
        }
      >
        <p>Artist: {track.artists.map((artist) => artist.name).join(", ")}</p>
      </TooltipTag>

      <TooltipTag
        tooltipId={albumTooltip?.id}
        tooltipContent={
          albumTooltip?.content || "Album information not available"
        }
      >
        <p>Album: {track.album.name}</p>
      </TooltipTag>

      <TooltipTag
        tooltipId={durationTooltip?.id}
        tooltipContent={
          durationTooltip?.content || "Duration information not available"
        }
      >
        <p>Duration: {formatDuration(track.duration_ms)}</p>
      </TooltipTag>
    </div>
  );
};

export default TrackPanel;
