import { Tooltip } from "react-tooltip";

const SpotifyPlayer = ({ trackId }) => {
  return (
    <div className="spotify-player mt-2">
      <iframe
        src={`https://open.spotify.com/embed/track/${trackId}`}
        width="250"
        height="250"
        allow="encrypted-media"
        allowFullScreen
        title="Spotify Player"
        data-tooltip-id="spotify-player"
        data-tooltip-content="Spotify Player"
      ></iframe>
      <Tooltip id="spotify-player" className="max-w-[400px] break-words" />
    </div>
  );
};

export default SpotifyPlayer;