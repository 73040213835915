import React from "react";
import { mapKeyToNotation, formatTimeSignature } from "./utils";
import { generateTooltipDetails, TooltipTag } from "./TooltipDetail";

const DisplayBox = ({ title, children, tooltipId, tooltipContent }) => (
  <TooltipTag tooltipId={tooltipId} tooltipContent={tooltipContent}>
    <div className="text-center w-[150px] m-4">
      <div className="lcd-box p-2 rounded-lg bg-green-300 text-black text-center">
        {children}
      </div>
      <p>
        <strong>{title}</strong>
      </p>
    </div>
  </TooltipTag>
);

const GenreDisplay = ({ genre, tooltipId, tooltipContent }) => (
  <TooltipTag tooltipId={tooltipId} tooltipContent={tooltipContent}>
    <div className="text-center w-[200px] m-4">
      <div className="scrolling-genre bg-green-300 text-black overflow-hidden border border-gray-700 rounded-md w-full h-[40px] flex items-center">
        <div className="scrolling-text whitespace-nowrap">
          {genre || "Unknown"}
        </div>
      </div>
      <p>
        <strong>Genre</strong>
      </p>
    </div>
  </TooltipTag>
);

const ControlPanel = ({ features, track }) => {
  const tooltipDetails = generateTooltipDetails(track, features);

  // Extract the tooltip data for Key, Genre, and Time Signature
  const keyTooltip = tooltipDetails.find((detail) => detail.id === "key");
  const genreTooltip = tooltipDetails.find((detail) => detail.id === "genre");
  const timeSignatureTooltip = tooltipDetails.find((detail) => detail.id === "time-signature");

  return (
    <div className="flex flex-col space-y-2 items-center md:flex-row md:space-y-0 md:space-x-4 md:justify-between items-center">
      {/* Mobile Layout */}
      <div className="flex flex-col items-center md:hidden">
        {/* Genre on top */}
        <GenreDisplay
          genre={track?.genre}
          tooltipId={genreTooltip?.id}
          tooltipContent={genreTooltip?.content}
        />

        {/* Key and Time Signature side by side */}
        <div className="flex space-x-4">
          <DisplayBox
            title="Key"
            tooltipId={keyTooltip?.id}
            tooltipContent={keyTooltip?.content}
          >
            <p>{mapKeyToNotation(features?.key)}</p>
          </DisplayBox>
          <DisplayBox
            title="Time Signature"
            tooltipId={timeSignatureTooltip?.id}
            tooltipContent={timeSignatureTooltip?.content}
          >
            <p>{formatTimeSignature(features?.time_signature)}</p>
          </DisplayBox>
        </div>
      </div>

      {/* Larger Screens Layout */}
      <div className="hidden md:flex w-full justify-between">
        <DisplayBox
          title="Key"
          tooltipId={keyTooltip?.id}
          tooltipContent={keyTooltip?.content}
        >
          <p>{mapKeyToNotation(features?.key)}</p>
        </DisplayBox>

        <GenreDisplay
          genre={track?.genre}
          tooltipId={genreTooltip?.id}
          tooltipContent={genreTooltip?.content}
        />

        <DisplayBox
          title="Time Signature"
          tooltipId={timeSignatureTooltip?.id}
          tooltipContent={timeSignatureTooltip?.content}
        >
          <p>{formatTimeSignature(features?.time_signature)}</p>
        </DisplayBox>
      </div>
    </div>
  );
};

export default ControlPanel;
