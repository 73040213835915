import React, { useEffect, useState } from 'react';
import SwitchSelector from "react-switch-selector";
import styled from 'styled-components';
import { TooltipTag } from './TooltipDetail';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  width: 120px;
  height: 50px;
  pointer-events: none;
`;

const ModeSwitch = ({ mode ,tooltipId, tooltipContent}) => {
  // State to manage the selected index
  const [selectedIndex, setSelectedIndex] = useState(mode === 0 ? 0 : 1);

  // Effect to update the selected index when mode changes
  useEffect(() => {
    const newIndex = mode === 0 ? 0 : 1;
    setSelectedIndex(newIndex); // Update state when mode changes
  }, [mode]); // Dependency array to listen for mode changes

  const options = [
    {
      label: <span>Minor</span>,
      value: 0, // Minor mode
      selectedBackgroundColor: "#007bff", // Blue for minor
      selectedFontColor: "#ffffff",
      id: `mode-minor-${Date.now()}`,
      name: "mode-switch",
    },
    {
      label: <span style={{ color: selectedIndex === 1 ? "#000000" : "#ffffff" }}>Major</span>,
      value: 1, // Major mode
      selectedBackgroundColor: "#ffd700", // Yellow for major
      selectedFontColor: "#000000",
      id: `mode-major-${Date.now()}`,
      name: "mode-switch",
    }
  ];

  return (
    <TooltipTag tooltipId={tooltipId} tooltipContent={tooltipContent}>
    <StyledContainer>
      <SwitchSelector
        options={options}
        initialSelectedIndex={selectedIndex} // Use selectedIndex state
        backgroundColor="#353b48" // Background color when not selected
        fontColor="#f5f6fa" // Text color when not selected
        style={{ width: 120, height: 50 }}
        onChange={() => {}} // Keep non-interactive
      />
    </StyledContainer>
    </TooltipTag>
  );
};

export default ModeSwitch;