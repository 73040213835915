import React from 'react';
import styled from 'styled-components';
import ReactSlider from 'react-slider';
import { TooltipTag } from './TooltipDetail';

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 25px;
  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #D30000 0%, #FFDE21 50%, #1ED760 100%);
    border-radius: 999px;
    z-index: 1; /* Ensure it is behind the thumb */
  }
`;

const StyledThumb = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  z-index: 2; /* Ensure thumb is above the track */
`;

const Thumb = ({ key: keyProp, ...props }, state) => {
  const uniqueKey = `thumb-${state.valueNow}`; // Using valueNow to create a unique key
  let emoji;

  if (state.valueNow === 100) {
    emoji = '💯'; // "100" emoji for value 100
  } else if (state.valueNow >= 50) {
    emoji = '👍'; // Thumbs up for 50-99
  } else {
    emoji = '👎'; // Thumbs down for 0-49
  }

  return <StyledThumb key={uniqueKey} {...props}>{emoji}</StyledThumb>
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: full; /* Adjust the width as needed */
  margin: 20px;
`;

const PopSlider = ({ value, label, onChange,tooltipId, tooltipContent  }) => (
  <TooltipTag tooltipId={tooltipId} tooltipContent={tooltipContent}>
  <StyledContainer>
    <div className='flex gap-x-5 items-center min-w-full'>
      <StyledSlider
        value={value}
        min={0}
        max={100}
        orientation="horizontal"
        renderThumb={Thumb}
        onAfterChange={onChange} // Optional: callback for change events
        disabled={true}
      />
      <div className="lcd-box p-2 rounded-lg bg-green-300 text-black text-center w-[80px]">
        <p>{value}</p>
      </div>
    </div>
    <p className="text-white font-bold mt-2">{label}</p>
  </StyledContainer>
  </TooltipTag>
);

export default PopSlider;