import React from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar';

const Navbar = ({ onSearch }) => { // Ensure onSearch is included in props
  const navigate = useNavigate();

  const handleLogoClick = () => {
    window.location.href = '/'; // This will refresh and navigate to the homepage
  };

  const handleSpotifyClick = () => {
    window.open('https://open.spotify.com/', '_blank'); // Navigates to Spotify
  };

  return (
    <nav className="bg-spotify-green flex justify-between items-center p-4  text-white">
      <img
        src={`${process.env.PUBLIC_URL}/img/bd-small.png`}
        alt="BeatDeetz Logo"
        onClick={handleLogoClick}
        className="h-10 w-auto cursor-pointer"
      />
      <SearchBar onSearch={onSearch} /> {/* Pass the search handler */}
      <img
        src={`${process.env.PUBLIC_URL}/img/Spotify_White.png`}
        alt="Spotify Logo"
        onClick={handleSpotifyClick}
        className="h-10 w-auto cursor-pointer"
      />
    </nav>
  );
};

export default Navbar;