import React from "react";
import { Knob } from "react-rotary-knob";
import * as skins from "react-rotary-knob-skin-pack";
import { TooltipTag } from './TooltipDetail';

const KnobDisplay = ({ label, value ,tooltipId, tooltipContent}) => {
  return (
    <TooltipTag tooltipId={tooltipId} tooltipContent={tooltipContent}>
    <div className="flex flex-col items-center text-center w-32 mr-4 ml-4">
      <div
        style={{
          height: "100px",
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          pointerEvents: "none", // Disable pointer events for interactivity
        }}
      >
        <Knob
          skin={skins.s10}
          rotateDegrees={180}
          min={0}
          max={100}
          value={value}
          style={{ width: "100px", height: "100px" }}
          readOnly // Make the knob read-only
        />
      </div>
      <p className="mt-auto">
        <strong>{label}</strong>
      </p>
    </div>
    </TooltipTag>
  );
};

export default KnobDisplay;