import React from "react";
import { Tooltip } from "react-tooltip";
import {
  formatDuration,
  mapKeyToNotation,
  formatTimeSignature,
  mapMode,
} from "./utils";

// Component to render individual tooltip details
const TooltipDetail = ({ tooltipId, tooltipContent, label, value }) => (
  <>
    <p
      className="text-white mb-2"
      data-tooltip-id={tooltipId}
      data-tooltip-content={tooltipContent}

    >
      <strong>{label}:</strong> {value}
    </p>
    <Tooltip id={tooltipId} className="custom-tooltip max-w-[300px] break-words" opacity={1} />
  </>
);

export const TooltipTag = ({ tooltipId, tooltipContent, children }) => (
  <>
    <div data-tooltip-id={tooltipId} data-tooltip-content={tooltipContent} >
      {children}
    </div>
    <Tooltip id={tooltipId} opacity={1} className="custom-tooltip max-w-[400px] break-words" />
  </>
);

export const generateTooltipDetails = (track, features) => {
  // Ensure track and features are not null or undefined
  if (!track || !features) {
    return []; // Return an empty array or some fallback data if track or features are missing
  }

  return [
  {
    id: "artist",
    content: "The artists who performed the track.",
    label: "Artist",
    value: track.artists.map((artist) => artist.name).join(", "),
  },
  {
    id: "album",
    content: "The album on which the track appears.",
    label: "Album",
    value: track.album.name,
  },
  {
    id: "duration",
    content: "The track length in minutes and seconds.",
    label: "Duration",
    value: formatDuration(track.duration_ms),
  },
  {
    id: "tempo",
    content:
      "The overall estimated tempo of a track in beats per minute (BPM).",
    label: "Tempo",
    value: `${features?.tempo.toFixed(2)} BPM`,
  },
  {
    id: "key",
    content: "The key the track is in.",
    label: "Key",
    value: mapKeyToNotation(features.key),
  },
  {
    id: "genre",
    content: "A list of the genres the artist is associated with.",
    label: "Genre",
    value: track.genre || "Unknown",
  },
  {
    id: "time-signature",
    content: "An estimated time signature.",
    label: "Time Signature",
    value: formatTimeSignature(features.time_signature),
  },
  {
    id: "loudness",
    content: "The overall loudness of a track in decibels (dB).",
    label: "Loudness",
    value: `${features?.loudness?.toFixed(2)} dB`,
  },
  {
    id: "explicit",
    content: "Whether or not the track has explicit lyrics.",
    label: "Explicit",
    value: track.explicit ? "Yes" : "No",
  },
  {
    id: "mode",
    content: "The modality (major or minor) of the track.",
    label: "Mode",
    value: mapMode(features.mode),
  },
  {
    id: "acousticness",
    content:
      "A confidence measure from 0 to 100 of whether the track is acoustic.",
    label: "Acousticness",
    value: `${(features?.acousticness * 100).toFixed(2)}%`,
  },
  {
    id: "liveness",
    content: "Detects the presence of an audience in the recording.",
    label: "Liveness",
    value: `${(features?.liveness * 100).toFixed(2)}%`,
  },
  {
    id: "energy",
    content: "A measure from 0 to 100 of the track's intensity and activity.",
    label: "Energy",
    value: `${(features?.energy * 100).toFixed(2)}%`,
  },
  {
    id: "danceability",
    content: "Describes how suitable a track is for dancing.",
    label: "Danceability",
    value: `${(features?.danceability * 100).toFixed(2)}%`,
  },
  {
    id: "instrumentalness",
    content: "Predicts whether a track contains no vocals.",
    label: "Instrumentalness",
    value: `${(features?.instrumentalness * 100).toFixed(2)}%`,
  },
  {
    id: "speechiness",
    content: "Detects the presence of spoken words in a track.",
    label: "Speechiness",
    value: `${(features?.speechiness * 100).toFixed(2)}%`,
  },
  {
    id: "valence",
    content: "The musical positiveness of a track.",
    label: "Valence",
    value: `${(features?.valence * 100).toFixed(2)}%`,
  },
  {
    id: "popularity",
    content: "A measure of how often a track is played.",
    label: "Popularity",
    value: `${track.popularity}%`,
  },
];
};

export default TooltipDetail;


