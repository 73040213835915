import React from 'react';
import SwitchSelector from "react-switch-selector";
import styled from 'styled-components';
import { TooltipTag } from './TooltipDetail';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  width: 120px;
  height: 50px;
  pointer-events: none;
`;

const ExplicitSwitch = ({ isExplicit, tooltipId, tooltipContent}) => {
  const options = [
    {
      label: <span>Clean</span>,
      value: false, // Clean (not explicit)
      selectedBackgroundColor: "#1ED760", // Green
      id: "explicit-clean",
      name: "explicit-switch",
    },
    {
      label: <span>Explicit</span>,
      value: true, // Explicit
      selectedBackgroundColor: "#ff0000", // Red
      id: "explicit-explicit",
      name: "explicit-switch",
    }
  ];

  const initialSelectedIndex = options.findIndex(option => option.value === isExplicit);

  return (
    <TooltipTag tooltipId={tooltipId} tooltipContent={tooltipContent}>
    <StyledContainer>
      <SwitchSelector
        options={options}
        initialSelectedIndex={initialSelectedIndex}
        backgroundColor="#353b48" // Background color when not selected
        fontColor="#f5f6fa" // Text color
        selectedFontColor="#ffffff" // Text color when selected
        style={{ width: 120, height: 50 }}
        onChange={() => {}} // Keep non-interactive
      />
    </StyledContainer>
    </TooltipTag>
  );
};

export default ExplicitSwitch;