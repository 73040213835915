import React, { useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';


const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState('');

  const handleSearch = () => {
    if (query.trim()) {
      onSearch(query);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="relative flex items-center">
      <input
        type="text"
        id="search-bar"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="Search for songs..."
        className="rounded-full bg-black text-white p-2 pl-4 pr-10 w-[200px] sm:w-[300px]"
      />
      <button
        onClick={handleSearch}
        className="absolute right-3"
      >
        <MagnifyingGlassIcon className="h-5 w-5 text-white" />
      </button>
    </div>
  );
};

export default SearchBar;