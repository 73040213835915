import React, { useState } from "react";
import TempoGauge from "./TempoGauge";
import LoudnessGauge from "./LoudnessGauge";
import MusicPanel from "./MusicPanel";
import SpotifyPlayer from "./SpotifyPlayer";
import { Tooltip } from "react-tooltip";
import SliderGroup from "./FeatureSlider";
import ControlPanel from "./ControlPanel";
import { AlbumCover } from "./utils";
import TooltipList from "./TooltipList";
import { generateTooltipDetails, tooltipDetails } from "./TooltipDetail";
import TrackPanel from "./TrackPanel";

const TrackDetails = ({ track, features, onBack }) => {
  // State to toggle between text and visualizer views
  const [isTextView, setIsTextView] = useState(true);

  // Toggle view function
  const toggleView = () => {
    setIsTextView(!isTextView);
  };

  const tooltipDetails = generateTooltipDetails
    ? generateTooltipDetails(track, features)
    : [];

  const loudnessTooltip = tooltipDetails.find(
    (detail) => detail.id === "loudness"
  );
  const tempoTooltip = tooltipDetails.find((detail) => detail.id === "tempo");

  return (
    <div className="mt-4 p-6 bg-black">
      {/* Toggle Switch for View */}
      <div className="flex justify-between ">
        <button
          onClick={onBack}
          data-tooltip-id="back-toggle"
          data-tooltip-content="Return to Home page"
          className="bg-gray-800 hover:bg-gray-300 p-2 rounded transition duration-200"
        >
          Back
        </button>
        <Tooltip id="back-toggle" className="max-w-[400px] break-words" />

        <button
          onClick={toggleView}
          data-tooltip-id="view-toggle"
          data-tooltip-content={
            isTextView
              ? "Displays Track Information in a sound mixing desk aesthetic"
              : "Displays Track Information in list format"
          }
          className={`p-2 rounded transition duration-200 ${
            isTextView ? "bg-green-500" : "bg-gray-800"
          }`}
        >
          {isTextView ? "Show Visualizer" : "Show Details"}
        </button>

        <Tooltip id="view-toggle" className="max-w-[400px] break-words" />
      </div>

      {isTextView ? (
        <div className="Text flex flex-col items-center justify-center">
          {/*DETAILS TEXT VIEW FOR ACCESSABILITY*/}
          {/*Album Cover */}
          <AlbumCover
            src={track.album.images[0]?.url}
            alt={`${track.album.name} album cover`}
            size={250}
          />

          {/* Track Name */}
          <h2
            className="text-3xl font-bold text-white mt-4"
            data-tooltip-id="track"
            data-tooltip-content="The name of the track."
          >
            {track.name}
          </h2>
          <Tooltip id="track" className="max-w-[400px] break-words" />

          {/* Track Details Array */}
          <div className="mt-4 flex-wrap max-w-[300px] text-center">
            <TooltipList features={features} track={track} />
          </div>
        </div>
      ) : (
        <div className="Visualiser">
          {/*VISUALISER VIEW FOR 3 SCREEN SIZES*/}
          {/* Desktop Layout */}
          <div className="hidden xl:block">
            <div className="flex justify-between items-center space-x-4">
              <AlbumCover
                src={track.album.images[0]?.url}
                alt={`${track.album.name} album cover`}
                size={250}
              />
              <TrackPanel features={features} track={track} />
              <SpotifyPlayer trackId={track.id} />
            </div>

            <div className="flex justify-between items-center space-x-4">
              <TempoGauge
                tempo={features?.tempo.toFixed(2) || 0}
                tooltipId={tempoTooltip?.id}
                tooltipContent={tempoTooltip?.content}
              />
              <ControlPanel features={features} track={track} />
              <LoudnessGauge
                loudness={features?.loudness.toFixed(2) || 0}
                tooltipId={loudnessTooltip?.id}
                tooltipContent={loudnessTooltip?.content}
              />
            </div>

            <div className="flex justify-between items-center ">
              <SliderGroup
                features={features}
                sliderType="vertical"
                labels={[
                  "energy",
                  "danceability",
                  "instrumentalness",
                  "speechiness",
                ]}
                tooltipDetails={tooltipDetails}
              />
              <MusicPanel features={features} track={track} />
            </div>
          </div>

          {/* Mobile Layout */}
          <div className="block md:hidden">
            <div className="flex flex-col justify-center items-center space-x-4">
              <AlbumCover
                src={track.album.images[0]?.url}
                alt={`${track.album.name} album cover`}
                size={250}
              />
              <TrackPanel features={features} track={track} />
              <SpotifyPlayer trackId={track.id} />
            </div>

            <div className="flex justify-center">
              <TempoGauge
                tempo={features?.tempo.toFixed(2) || 0}
                tooltipId={tempoTooltip?.id}
                tooltipContent={tempoTooltip?.content}
              />
            </div>
            <div className="flex justify-center">
              <LoudnessGauge
                loudness={features?.loudness.toFixed(2) || 0}
                tooltipId={loudnessTooltip?.id}
                tooltipContent={loudnessTooltip?.content}
              />
            </div>

            <ControlPanel features={features} track={track} />
            <br></br>
            <SliderGroup
              features={features}
              sliderType="horizontal"
              labels={[
                "energy",
                "danceability",
                "instrumentalness",
                "speechiness",
              ]}
              tooltipDetails={tooltipDetails}
            />
            <MusicPanel features={features} track={track} />
          </div>

          {/* Mid Display */}
          <div className="custom-md-lg hidden md:block xl:hidden">
            <div className="flex justify-between items-center space-x-4">
              <AlbumCover
                src={track.album.images[0]?.url}
                alt={`${track.album.name} album cover`}
                size={250}
              />

              <TrackPanel features={features} track={track} />

              <div>
                <SpotifyPlayer trackId={track.id} />
              </div>
            </div>

            <div className="flex justify-center mb-4">
              <TempoGauge
                tempo={features?.tempo.toFixed(2) || 0}
                tooltipId={tempoTooltip?.id}
                tooltipContent={tempoTooltip?.content}
              />

              <LoudnessGauge
                loudness={features?.loudness.toFixed(2) || 0}
                tooltipId={loudnessTooltip?.id}
                tooltipContent={loudnessTooltip?.content}
              />
            </div>

            <ControlPanel features={features} track={track} />
            <br></br>

            <SliderGroup
              features={features}
              sliderType="vertical"
              labels={[
                "energy",
                "danceability",
                "instrumentalness",
                "speechiness",
              ]}
              tooltipDetails={tooltipDetails}
            />
            <br></br>

            <MusicPanel features={features} track={track} />
          </div>
        </div>
      )}
    </div>
  );
};

export default TrackDetails;
