import React from 'react';

const handleSpotifyClick = () => {
    window.open('https://open.spotify.com/', '_blank'); // Navigates to Spotify
  };

const Footer = () => {
  return (
    <footer className="bg-spotify-green text-white py-4 mt-4 text-center">
<div className="flex justify-center items-center space-x-2">
  <p className="text-sm">
    © {new Date().getFullYear()} BEATDEETZ. Song data and Audio Playback provided by
  </p>
  <img
    src={`${process.env.PUBLIC_URL}/img/Spotify_Full_White.png`}
    alt="Spotify Logo"
    onClick={handleSpotifyClick}
    className="h-8 w-auto cursor-pointer"
  />
</div>
    </footer>
  );
};

export default Footer;